@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-image: linear-gradient(to top left , #8c33ff 50%, indigo 50%);
  /* background-color: indigo; */
}
.container {
  padding: 1rem;
  background-color: white;
  box-shadow: 5px;
}

.title {
  padding: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select,
.form-control textarea {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%; /* Adjust width to 100% for responsiveness */
  max-width: 100%;
}

button {
  font: inherit;
  background-color: #292929;
  color: white;
  border: 1px solid #292929;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions {
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 480px)  {
  .main {
    /* zoom: 180%; */
    /* margin-top: 150px; */
  }
  .container {
    width:100%;
    /* margin: 1rem;  */
    /*  */
  }

  .title {
    font-size: 1.5rem; /* Increase font size for readability on mobile */
  }

  .form-control input,
  .form-control select,
  .form-control textarea {
    width: 100%; /* Set width to 100% for full-width on mobile */
  }

  button {
    width: 100%; /* Set button width to 100% for full-width on mobile */
  }
}

@media only screen and (min-width: 1080px)  {
  .main{
    zoom:100%;
    margin-top:0px;
  }
  .container {
    
    margin: 1rem; 
  }

  .title {
    font-size: 1.5rem; /* Increase font size for readability on mobile */
  }

  .form-control input,
  .form-control select,
  .form-control textarea {
    width: 100%; /* Set width to 100% for full-width on mobile */
  }

  button {
    width: 100%; /* Set button width to 100% for full-width on mobile */
  }
}